import * as React from "react"
import { Layout } from "../../components/Layout"
import { SmokeFusion } from "../../components/SmokeFusion"
import { Helmet } from "react-helmet"

const SmokeFusionPage = () => {
  return (
    <Layout>
      <Helmet htmlAttributes={{ lang: "en" }}>
        <meta charSet="utf-8" />
        <title>OakPong - SmokeFusion</title>
        <meta
          name="description"
          content="OakPong - new ideas for your company"
        />
      </Helmet>
      <SmokeFusion />
    </Layout>
  )
}

export default SmokeFusionPage
